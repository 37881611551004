<template>
    <a-space direction="vertical" style="width: 100%">
      <a-card>
        <a-row :gutter="16">
          <a-col :span="6">
            <community-join ref="communityJoin"/>
          </a-col>

          <a-col :span="18">
            <data-alarm ref="dataAlarm"
                        @changeQuery="(query)=>{$refs.communityJoin.query=query; $refs.communityJoin.customQuery();}"/>
          </a-col>
        </a-row>
      </a-card>

      <a-row :gutter="16">
        <a-col :span="12">
          <heat-map/>
        </a-col>

        <a-col :span="12">
          <curve-line/>
        </a-col>
      </a-row>
    </a-space>
</template>

<script>
import CommunityJoin from './communityJoin'
import DataAlarm from './dataAlarm'
import HeatMap from './heatmap'
import CurveLine from './curveLine'
export default {
    components: {
        CommunityJoin, DataAlarm, HeatMap, CurveLine
    },
    data() {
        return {
            loading: true,
            query: {
                time: []
            },
        }
    },
    mounted() {
        this.$refs.communityJoin.queryCommunityJoinCondition(this.query)
        this.$refs.dataAlarm.queryDataAlarmList(this.query)
    }
}
</script>